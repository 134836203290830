var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.$vuetify.breakpoint.mobile)?_c('v-textarea',{attrs:{"dense":"","rows":"3","label":"* Descricao","rules":[
                      this.$validators.string.required,
                          function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                          function (v) { return this$1.$validators.string.lessThanOrEquals(v, 150); }

                  ]},model:{value:(_vm.value.descricao),callback:function ($$v) {_vm.$set(_vm.value, "descricao", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.descricao"}}):_c('v-text-field',{attrs:{"label":"* Descricao","dense":"","rules":[
                      this.$validators.string.required,
                          function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                          function (v) { return this$1.$validators.string.lessThanOrEquals(v, 150); }

                  ]},model:{value:(_vm.value.descricao),callback:function ($$v) {_vm.$set(_vm.value, "descricao", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.descricao"}}),(_vm.$vuetify.breakpoint.mobile)?_c('v-textarea',{attrs:{"dense":"","rows":"3","label":"* Nome","rules":[
                      this.$validators.string.required,
                          function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                          function (v) { return this$1.$validators.string.lessThanOrEquals(v, 150); }

                  ]},model:{value:(_vm.value.nome),callback:function ($$v) {_vm.$set(_vm.value, "nome", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.nome"}}):_c('v-text-field',{attrs:{"label":"* Nome","dense":"","rules":[
                      this.$validators.string.required,
                          function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                          function (v) { return this$1.$validators.string.lessThanOrEquals(v, 150); }

                  ]},model:{value:(_vm.value.nome),callback:function ($$v) {_vm.$set(_vm.value, "nome", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.nome"}}),(_vm.$vuetify.breakpoint.mobile)?_c('v-textarea',{attrs:{"dense":"","rows":"3","label":"* Valor","rules":[
                      this.$validators.string.required,
                          function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                          function (v) { return this$1.$validators.string.lessThanOrEquals(v, 150); }

                  ]},model:{value:(_vm.value.valor),callback:function ($$v) {_vm.$set(_vm.value, "valor", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.valor"}}):_c('v-text-field',{attrs:{"label":"* Valor","dense":"","rules":[
                      this.$validators.string.required,
                          function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                          function (v) { return this$1.$validators.string.lessThanOrEquals(v, 150); }

                  ]},model:{value:(_vm.value.valor),callback:function ($$v) {_vm.$set(_vm.value, "valor", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.valor"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }